import { createTheme } from '@material-ui/core/styles';
import { WS_URL } from './config';

export const theme = createTheme({
  palette: {
    primary: {
      main: WS_URL.includes("localhost") ? '#00bcd4' : '#d00273',
    },
    secondary: {
      main: '#6363ce',
    },
  },
});